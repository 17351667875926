<template>
  <section>
    <propertiesPageHeader
      :name="property.name"
      :address="property.address"
      :units="property.units"
      :applianceCounts="property.applianceCounts"
      :managerName="property.managerName"
      :managerEmail="property.managerEmail"
    ></propertiesPageHeader>
    <div class="gradient_spacer relative w-full m-0"></div>
    <div class="rounded grey p-3 pb-10 ml-5 mr-1">
      <div class="text-ltgrey w-full relative pb-2">
        <h1 class="inline-block font-extrabold text-14">FLOOR 1</h1>
        <div class="ml-2 inline-block text-12">{{ floor.units }} {{ roomLabel }}s</div>
        <router-link
          to="/properties/show/building-1/floor-1"
          tag="a"
          class="float-right block font-extrabold text-14"
          >X</router-link
        >
      </div>
      <roomDetails></roomDetails>
    </div>
    <div class="gradient-floor fixed bottom-0 w-full left-0 pointer-events-none"></div>
    <!-- MODAL -->
    <div v-if="showModal" class="modal-route" @click.self="$router.go(-1)">
      <div class="modal-content grid grid-cols-12">
        <div class="col-span-12">
          <div class="pb-7">
            <router-link
              to="/properties/show/building-1/floor-1/room-101"
              tag="a"
              class="float-right block font-extrabold text-14 text-white"
              >X</router-link
            >
          </div>
          <div class="relative rounded p-5 bg-white">
            <router-view></router-view>
          </div>
        </div>
      </div>
    </div>
    <!-- END MODAL -->
  </section>
</template>

<script>
  import propertiesPageHeader from '@/components/propertiesPageHeader.vue';
  import roomDetails from '@/components/propertiesRoomDetailsBlock.vue';
  import { getDataByHostname } from '../utils/dataUtil';

  export default {
    components: { propertiesPageHeader, roomDetails },
    data() {
      return {
        property: [{ name: '', applianceCounts: {}, buildings: [] }],
        dataArray: [],
        showModal: false,
      };
    },
    mounted() {
      const dataByHostName = getDataByHostname();
      this.property = dataByHostName?.properties?.[0] || {};

      for (var i = 102; i < 224; i++) {
        this.dataArray.push({
          name: i,
          occupied: Math.random() < 0.5,
          cleaning: Math.random() < 0.15,
        });
      }
    },
    watch: {
      $route: {
        immediate: true,
        handler: function(newVal, oldVal) {
          if (newVal !== oldVal) {
            this.showModal = newVal.meta && newVal.meta.showModal;
          }
        },
      },
    },
    methods: {},
    computed: {
      roomLabel: function() {
        return this.$store.getters.propertyLabels('room');
      },
      floor: function() {
        return this.$store.state.properties[0].buildings[0].floors[0];
      },
    },
  };
</script>
<style lang="scss" scope>
  @import '../styles/typovars';
  section {
    padding-bottom: 220px;
  }
  .gradient_spacer {
    border: none;
  }
  .modal-route {
    z-index: 3000;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba($color: #000000, $alpha: 0.9);
    .modal-content {
      width: 95vw;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      // background: white;
    }
  }
</style>
