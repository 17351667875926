<template>
  <div>
    <div id="propsDetailsBlock" class="rounded dkgrey p-5 mr-8">
      <div class="float-right text-ltgrey inline-block" @click="closeDetails">X</div>
      <div class="flex">
        <div class="flex-none mr-5">
          <div class="text-ltgrey text-sm mb-2">{{ roomLabel }}</div>
          <div class="font-semibold text-4xl text-white mb-2">101</div>
          <div class="text-white mb-8">Occupied</div>
          <div class="text-ltgrey">ONLINE: <span class="text-green font-bold">5</span></div>
          <div class="text-ltgrey">OFFLINE: <span class="text-yellow font-bold">1</span></div>
          <div class="text-ltgrey">ERRORS: <span class="text-red font-bold">1</span></div>
        </div>

        <div class="mt-8 ml-14 flex-grow grid lg:grid-cols-2 md:grid-cols-1">
          <div
            class="col-span-1 rounded py-3 px-5 mr-5 mb-5 red cursor-pointer"
            @click="blockClick('zoneline')"
          >
            <div class="w-full">
              <span class="float-right font-extrabold text-lg text-white leading-none"
                >OFFLINE</span
              >
            </div>
            <div class="w-full lg:flex md:block">
              <div class="flex-none lg:pl-4 md:pl-0 pr-6">
                <img src="@/assets/img/room_icons/zoneline.png" />
              </div>
              <div class="flex-grow text-white">
                <div class="text-sm font-semibold mb-3">
                  {{ $store.state.applianceLabels.zoneline.name }}
                </div>
                <div class="text-xs mb-5">
                  Mode <span class="float-right text-sm text-bold">Cool</span>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="$store.state.clientName === 'Carlisle'"
            class="col-span-1 rounded py-3 px-5 mr-5 mb-5 grey cursor-pointer"
          >
            <div class="w-full">
              <span class="float-right font-extrabold text-4xl text-green leading-none">•</span>
            </div>
            <div class="w-full lg:flex md:block">
              <div class="flex-none lg:pl-4 md:pl-0 pr-6">
                <img src="/images/batch_icons/Refrigerator.svg" />
              </div>
              <div class="flex-grow text-white">
                <div class="text-sm font-semibold mb-3">Refrigerator</div>
                <div class="text-xs mb-5">
                  Mode <span class="float-right text-sm text-bold">Cool</span>
                </div>
              </div>
            </div>
          </div>
          <div
            v-else
            class="col-span-1 rounded py-3 px-5 mr-5 mb-5 grey cursor-pointer"
            @click="blockClick('dfs')"
          >
            <div class="w-full">
              <span class="float-right font-extrabold text-4xl text-green leading-none">•</span>
            </div>
            <div class="w-full lg:flex md:block">
              <div class="flex-none lg:pl-4 md:pl-0 pr-6">
                <img src="@/assets/img/room_icons/zoneline.png" />
              </div>
              <div class="flex-grow text-white">
                <div class="text-sm font-semibold mb-3">DFS</div>
                <div class="text-xs mb-5">
                  Mode <span class="float-right text-sm text-bold">Cool</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-span-1 rounded py-3 px-5 pt-2 mr-5 mb-5 grey cursor-not-allowed">
            <div class="w-full">
              <span class="float-right font-extrabold text-4xl text-green leading-none">•</span>
            </div>
            <div class="w-full flex">
              <div class="flex-none lg:pl-4 md:pl-0 pr-6">
                <img src="@/assets/img/room_icons/range.png" />
              </div>
              <div class="flex-grow text-white">
                <div class="text-sm font-semibold mb-3">Range</div>
                <div class="text-xs mb-5">
                  Cook Timer <span class="float-right text-sm text-bold">02:37:45</span>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-span-1 rounded py-3 px-5 pt-2 mr-5 mb-5 grey cursor-pointer"
            @click="blockClick('dishwasher')"
          >
            <div class="w-full">
              <span class="float-right font-extrabold text-4xl text-green leading-none">•</span>
            </div>
            <div class="w-full flex">
              <div class="flex-none lg:pl-4 md:pl-0 pr-6">
                <img src="@/assets/img/room_icons/dishwasher.png" />
              </div>
              <div class="flex-grow text-white">
                <div class="text-sm font-semibold mb-3">Dishwasher</div>
                <div class="text-xs mb-5">
                  Time Remaining <span class="float-right text-sm text-bold">02:15</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-span-1 rounded py-3 px-5 pt-2 mr-5 mb-5 grey cursor-not-allowed">
            <div class="w-full">
              <span class="float-right font-extrabold text-4xl text-green leading-none">•</span>
            </div>
            <div class="w-full flex">
              <div class="flex-none lg:pl-4 md:pl-0 pr-6">
                <img src="@/assets/img/room_icons/washingmachine.png" />
              </div>
              <div class="flex-grow text-white">
                <div class="text-sm font-semibold mb-3">Washing Machine</div>
                <div class="text-xs mb-5">
                  Wash Cycle <span class="float-right text-sm text-bold">Normal</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-span-1 rounded py-3 px-5 pt-2 mr-5 mb-5 grey cursor-not-allowed">
            <div class="w-full">
              <span class="float-right font-extrabold text-4xl text-green leading-none">•</span>
            </div>
            <div class="w-full flex">
              <div class="flex-none lg:pl-4 md:pl-0 pr-6">
                <img src="@/assets/img/room_icons/washingmachine.png" />
              </div>
              <div class="flex-grow text-white">
                <div class="text-sm font-semibold mb-3">Dryer</div>
                <div class="text-xs mb-5">
                  Dry Cycle <span class="float-right text-sm text-bold">High</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      name: {
        type: String,
        default: '101',
      },
      occupied: {
        type: Boolean,
        default: false,
      },
      cleaning: {
        type: Boolean,
        default: false,
      },
      error: {
        type: Boolean,
        default: false,
      },
      url: {
        type: String,
        default: 'zoneline',
      },
      dfsurl: {
        type: String,
        default: 'dfs',
      },
      dishurl: {
        type: String,
        default: 'dishwasher',
      },
    },
    components: {},
    data() {
      return {
        roomName: this.name,
        roomOccupied: this.occupied,
        roomCleaning: this.cleaning,
        roomError: this.error,
        roomUrl: this.url,
        dfsUrl: this.dfsurl,
        dishUrl: this.dishurl,
      };
    },
    computed: {
      roomLabel: function() {
        return this.$store.getters.propertyLabels('room');
      },
    },
    mounted() {
      // window.addEventListener('onresize', this.onResize.bind(this));
    },
    watch: {},
    methods: {
      onResize: function() {
        // 1018;
        // 499;
      },
      blockClick: function(route) {
        this.$router.push(`/properties/show/building-1/floor-1/room-101/${route}`);
      },
      closeDetails: function() {
        this.$router.push('/properties/show/building-1/floor-1');
      },
    },
  };
</script>
<style lang="scss" scope>
  @import '../styles/typovars';

  svg#propsDetailsBlock {
    transform-origin: top left;
  }
</style>
